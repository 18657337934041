.AppContainer {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  height: auto;
  width: 100%;
}

.PageContainer {
  display: flex;
  background-color: #F7F8FB;
  margin-top: 80px;
  height: calc(100vh - 80px);
  width: 100%;
}

a {
  text-decoration: none;
}

a:hover {
    text-decoration: underline;
}