.mapAndSearchContainer {
  width: calc(100% - 200px);
  z-index: 15;
}

.mapLoader {
  width: 100%;
  height: 100%;
  background: #f7f8fb;
  z-index: 100;
  position: fixed;
}

.mapSearchBar {
  width: 100%;
  background: #f7f8fb;
  height: 65px;
  z-index: 16;
  position: absolute;
}

.innerMapContainer {
  position: absolute;
  top: 80px;
  height: calc(100% - 80px);
  width: inherit;
}

@media (max-width: 992px) {
  // At sizes smaller than desktop, no need to calculate the 200px
  // offset created by the sidebar
  .mapAndSearchContainer {
    width: 100%;
  }
}

.mapSearchInput {
  margin: 12px;
  font-family: "Avenir Next", sans-serif;
  font-weight: 500;
  width: 400px;
}

.mapSearchIcon {
  position: absolute;
  top: 23px;
  right: 10px;
  opacity: 0.4;
}

.buttonGroup {
  text-align: start;
  padding: 0px 20px 20px 20px;
  cursor: pointer;
}

.incidentLocation {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  color: #40A4FC !important;
}

.priorityIcon {
  margin-top: 15px;
  max-width: 30px;
  max-height: 14px;
  width: auto;
  height: auto;
}

.blockerContent, .assetContent {
  padding: 10px 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.blockerContent {
  padding: 20px;
}

.blockerOrganization {
  padding-bottom: 2px;
}

.blockerSummary {
  font-size: 18px;
  padding-bottom: 20px;
}

.assetLocation {
  margin: 5px 0 10px;
}

.popupLink {
  margin: 10px 0 5px;
  padding: 5px 0;
  color: #40A4FC;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
}

.popupLink:hover {
  color: #56affd;
}

.generatorTimer {
  font-weight: bold;
  font-size: x-large;
}

.utilityDetailsContainer {
  cursor: default;
  border-radius: 5px;
  margin-top: 10px;
  padding: 5px 5px 10px;
  border: 1px solid red;
  height: auto;
}

.utilityDetailsContainer h3 {
  margin: 5px;
  font-size: 1.2em;
}

.utilityDetailsContainer p {
  margin: 0 5px;
  font-size: 1em;
}

.etrLabel {
  padding-bottom: 2px;
}

.etrTimestamp {
  font-size: 18px;
  padding-bottom: 20px;
}

.legendDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0;
  margin-right: 5px;
}

.legendSymbol {
  display: flex;
  width: 40px;
}

.legendDoubleLabel {
  display: flex;
  flex-direction: column;
}

.legendDoubleLabel div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.layerIcon {
  width: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.layerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: unset;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.layerItem input {
  margin-left: auto;
}

.layerFilterTooltip {
  z-index: 99 !important;
}

.legendLink {
  color: #40a4fc;
  text-decoration: none;

  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
}

.hiddenCheckbox {
  visibility: hidden;
}

.layerToggleGroup h6 {
  text-align: center;
  padding: 10px 0 10px;
  margin-bottom: unset;
}

.layerToggleButton {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  width: 50px;
  height: 50px;
  padding: 0px;
  margin-right: 20px;
  border-radius: 25px;
}

.layerToggleImage {
  width: 50px;
  height: 50px;
}

.layersPopup {
  font-family: "Avenir Next", sans-serif;
  width: 300px;
  font-size: 18px;
}

.layersPopupTitle {
  font-family: "Avenir Next", sans-serif;
  color: grey;
}

.layersPopupItem {
  padding: 10px 25px;
  font-size: 16px;
}

.layersPopupItem > label {
  padding-left: 10px;
}

.incidentSelectionPopup {
  width: 250px;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.incidentSelectionPopupItem {
  padding: 5px 0px 0px 25px;
}

.incidentSelectionPopupInput {
  position: relative;
}

.incidentSelectionPopupLabel {
  font-family: "Avenir Next", sans-serif;
  padding-left: 20px;
}

.incidentSelectionPopupLabel > img {
  padding-right: 10px;
}

.drawPointButton {
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  border-radius: 3px;
}

.drawPointButton:hover {
  background-color: #108000 !important;
}

.formRowNoMargins {
  margin: 0;
}

.partnerTitle {
  font-family: "Avenir Next", sans-serif;
}

.partnerSubtitle {
  font-family: "Avenir Next", sans-serif;
  color: grey;
}

.partnerListItem {
  font-family: "Avenir Next", sans-serif;
  margin: 1rem 0;
}

.showPartnersLink {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.partnerPopupRow {
  padding: 10px 25px;
}

.partnerPopupLabel {
  margin-left: 0.5rem;
  font-size: 16px;
}

.partnerPopupContent {
  padding: 10px 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partnerPopupOrganization {
  padding-bottom: 2px;
}

.partnerPopupType {
  font-size: 18px;
}

.partnerIncidentLocation {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.partnerIncidentLocationDetail {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  font-size: 13px;
}

.partnerIncidentEtrTimestamp {
  font-size: 18px;
}

.ticketInformation {
  margin-bottom: 40px;
}

.editAssetContainer {
  margin-bottom: 40px;
}

.editAssetContainer h3 {
  margin-bottom: 20px;
}
.editAssetButtonRow {
  display: flex;
  justify-content: space-around;
  padding: 40px 20px;
}
.group_label {
  margin-bottom: 0;
  margin-top: 1rem;
}

.xbutton {
  width: 6px;
  height: 6px;
  display: block;
  padding: 5px;
}

.utilityRanking {
  max-height: 220px;
  overflow-y: scroll;
}

.miniList {
  overflow-y: scroll;
  flex-grow: 1;
  margin-bottom: 5px;

  .title {
    padding: 10px;
    font-weight: bold;
  }
}

.sidebarCard {
  margin: 10px;
  font-size: 12px;

  dd{
    word-break: break-word;
  }
}

.popupName {
  text-align: center;
  font-weight: bold;
}

.relatedElementCell {
  font-size: 13px;
  white-space: normal;
  word-break: break-word;
  text-overflow: unset;

  /* Temporal workaround to allow text selection in popups */
  user-select: all;
  cursor: text;
}

.createAssetButton{
  cursor: pointer;
  color: #555555;
  text-decoration: underline;
}

.assetsContainer{
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.relatedAssetContainer{
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 5px 0 5px 5px;
  margin: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.relatedAssetSelector{
  border-radius: 2px;
  padding: 7px 3px;
  &:hover{
    background-color: #FFBDAD;
    color: #DE350B;
  }
}

.createdAssetContainer{
  background-color: hsl(125, 96%, 80%);
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 5px;
  margin: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.utilityPhoneNumberName{
  text-decoration: underline;
}

.utilityPhoneNumber{
  line-height: 1.2;
}
