header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2F3033;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 19;
}

.navbar {
  min-height: 80px;
  width: 100%;
  background-color: #2F3033;
  padding: 20px 30px;
  /* Side Sheet from Evergreen UI has Z-index 20, so must be below that */
  z-index: 19;
}

.navbarCollapse {
  padding-top: 15px;
}

.logo {
  height: 35px;
}

.authDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: white;
  margin: 5px;
  list-style: none;
}

.helpLink {
  font-family: 'Avenir Next', sans-serif;
  color: white;
  font-size: 12px;
}

.helpLink:hover {
  text-decoration: none;
}

.profileInfo {
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 0;
}

.subProfile {
  font-family: 'Avenir Next', sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  margin: 0;
}

.profileInfoNav {
  color: #212529 !important;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.subProfileNav {
  color: #212529 !important;
  font-family: 'Avenir Next', sans-serif;
  font-size: 12px;
}

.helpLink:hover {
  color: white;
}

.dropdownProfile {
  background: url("../../assets/default-user-icon.png") no-repeat;
  background-size: contain;
  border-radius: 25px;
  height: 40px;
  width: 40px;
  border: 1px solid #FFFFFF;
}

.dropdownProfile>button {
  box-shadow: none !important;
}

.dropdownItem:hover,
.dropdownItem:active {
  box-shadow: none;
}

.dropdownLink:hover,
.dropdownLink:active {
  text-decoration: none;
}

.verticalSeparator {
  border-left: 1px solid #393A3D;
  height: 55px;
  margin: 0 20px;
}

.dropdownProfile>button,
.dropdownProfile>button:hover,
.dropdownProfile>button:active,
.dropdownProfile>button:focus {
  background-color: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  border-radius: 25px;
  box-shadow: none;
  background-image: url("../../assets/default-user-icon.png");
}

.helpLinkIcon {
  height: 20px;
}

.hidden {
  display: none;
}

.navLink, .navLink > a {
  color: #FFFFFF !important;
}

.navLink:hover, .navLink > a:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.navDropdownLink {
  color: #212529;
}

.navDropdownLink:hover {
  text-decoration: none;
  color: #212529;
}

.navDropdownItem:active {
  background-color: rgba(0, 0, 0, 0.5);
}

.navDropdownItem:active > a {
  color: #FFFFFF;
}

.activeLink {
  font-weight: bold;
}
