.mapLegendDot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 0;
  margin-right: 8px;
}

.showOnHover {
  display: none;
}

.mapLegend {
  &:hover {
    .showOnHover {
      display: inline-block;
      margin-right: 7px;
    }

    .hideOnHover {
      display: none;
    }
  }
}
