body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#app {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  cursor: pointer;
}

/*

  font: inherit;
  background: #ffffff;
  padding: 0.5rem 2rem;
  color: #000000;
  border: 1px solid #8D9096;
  margin: 0.5rem 0;
  border-radius: 1px;

  */

button:hover,
button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

button:focus {
  outline: none;
}

.mapboxgl-popup-content {
  font-size: 15px;
  padding: 0px;
}

.location-content {
  padding: 20px 20px 0px 20px;
  width: 300px;
}

.mapboxgl-popup-content .attribute {
  margin-bottom: 5px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.mapboxgl-popup-content .attribute b {
  word-break: break-all;
}

.mapboxgl-popup-content .attribute img {
  height: 20px;
  width: 20px;
}

.mapboxgl-popup-content .location {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mapboxgl-popup-content .location-detail {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  align-items: flex-start;
}

.mapboxgl-popup-content .address {
  font-size: 13px;
}

.mapboxgl-popup-content .latlng {
  font-size: 13px;
}

.mapboxgl-popup-content .date-label {
  font-size: 18px;
  margin-top: 1.0rem;
}

.mapboxgl-popup-content .location-type {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 140px;
  left:-132px;
  border-radius: 10px 0 0 10px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
}

.mapboxgl-popup {
  background: rgba(0,0,0,0) !important;
}

.mapboxgl-popup-content {
  border-radius: 10px;
}

.react-selectize.root-node {
    width: 100% !important;
}

.react-selectize {
  display: block !important;
}

.mapboxgl-ctrl-top-left {
  /* Above search bar, below create/edit drawer */
  z-index: 16 !important;
  top: -2px !important;
  left: inherit !important;
  right: 30px;
}

.mapboxgl-ctrl-group > button {
  width: 180px !important;
  margin: 5px 0 !important;
  background-image: none !important;
  box-shadow: none !important;
  color: white !important;
  height: 35px !important;
  background: #11b24b !important;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none !important;
}

.mapboxgl-ctrl-group {
  background: none !important;
}

.asset-date {
  margin-left: 70px;
}
.search-panel {
  height: 60px;
  position: absolute;
  width: 100%;
  z-index: 11;
  top: 60px;
  left: 0px;
  background: #efefef;
}
.search-input {
  width: 55%;
  margin: 10px;
  background: #efefef;
  border: 1px solid #d8d8d8;
}
.mapbox-gl-draw_trash {
  display: none;
}

.popover {
  z-index:20;
}

.globalCursorPoint {
  cursor: pointer
}

.isolatedCursorPointer {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}


/* Styles to allow users to right-click paste into the address bar */
.address-bar__single-value {
  pointer-events: none;
}

.address-bar__placeholder {
  pointer-events: none;
}

.address-bar__input {
  width: 100%;
}

.address-bar__input > input {
  width: 100% !important;
}
