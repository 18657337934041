.mainContent {
  background-color: #FFFFFF;
  order: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.createButtonArea {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}

.searchAssetArea{
  display: flex;
  padding-left: 30px;
  align-items: center;
  justify-content: flex-start;
  background-color: #F7F8FB;
  width: 500px !important;
}
.headerArea{
  display: flex;
  flex-direction: row;
  background-color: #F7F8FB;
  width: 100%;
}

.seeMoreContainer {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.seeMoreButton {
  background-color: transparent;
  border: none;
  color: #40A4FC;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 0;
  &:hover {
    text-decoration: underline;
    background: transparent;
    border: 0px;
    box-shadow: none;
    color: #40A4FC;
  }
  &:active{
    background-color: transparent !important;
    border-color: #40A4FC !important;
    color: #fff !important;
    box-shadow: none !important;
  }
  &:focus{
    border: 0px;
    box-shadow: none !important;
  }
}

.editAssetContainer{
  margin-top: 10px;
  height: 100%;
  margin-left: auto;
}

.createButton {
  font-family: "Avenir Next", sans-serif;
  font-weight: 400;
  font-size: 16px;
  float: right;
  border: 0;
  background-color: #11B14B;
  margin-bottom: 10px;
  border-radius: 2px;
  height: 35px;
}

.viewListButton{
  font-size: 14px;
  height: 30px;
  background-color: rgb(66, 90, 112);
  border: 0px;
}

.createButton:hover {
  background-color: #108000;
}

.createButton:active {
  background-color: #108000 !important;
}

.sortButtonArea {
  background-color: #FFFFFF;
  padding: 20px 30px;
  width: 100%;
}

.sortButton {
  font-family: "Avenir Next", sans-serif;
  font-weight: 300;
  font-size: 20px;
  background-color: #FFFFFF;
  border: 1px solid #8D9096;
  color: #393A3D;
  margin-bottom: 10px;
  border-radius: 2px;
  width: 120px;
}

.sortButton:hover {
  background-color: #DCDCDC;
  border: 1px solid #808896;
  color: #393A3D;
}

.sortButton:active {
  background-color: #DCDCDC !important;
}

.tableWrapper {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background-color: #f7f8fb;
}

.contentList {
  background-color: #f7f8fb;
  padding: 0 30px;
  width: 100%;
  min-width: 992px;
}

@media (min-width: 992px) {
  /* 992px, the desktop width, minus 200px, the sidebar width.
  Ensures the Add Incident button doesn't overflow. */
  .mainContent {
    min-width: 792px;
  }
}

.dropdown-item-button {
  background: none;
}

.tableContent {
  padding: 40px;
  background-color: white;
}

.deleteModalBody {
  padding: 30px 45px;
}

.deleteModalBodyContent {
  padding: 0 10px;
}

.deleteModalText {
  font-family: "Avenir Next", sans-serif;
  font-weight: 500;
  text-align: center;
}

.deleteModalTextDetail {
  font-family: "Avenir Next", sans-serif;
  font-weight: 300;
  text-align: center;
}

.alertIcon {
  display: block;
  width: 80px;
  margin: 40px auto;
}

.cancelDeleteButton {
  font-family: "Avenir Next", sans-serif;
  font-weight: 300;
  font-size: 20px;
  background-color: #FFFFFF;
  border: 1px solid #979797;
  color: #000000;
  margin-bottom: 10px;
  border-radius: 2px;
  width: 125px;
}

.confirmDeleteButton {
  float: right;
  font-family: "Avenir Next", sans-serif;
  font-weight: 300;
  font-size: 20px;
  background-color: #E74C3C;
  border: 1px solid #E74C3C;
  color: #FFFFFF;
  margin-bottom: 10px;
  border-radius: 2px;
  width: 125px;
}

.confirmDeleteButton:hover {
  background-color: #DCDCDC;
  border: 1px solid #808896;
  color: #393A3D;
}

.confirmDeleteButton:active {
  background-color: #DCDCDC !important;
}

tr, td {
  border: 2px solid #DDDDDD;
  vertical-align: middle;
}

.etrClock {
  margin-right: 10px;
}

.paginateBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginateBtn {
  display: inline-flex;
  justify-content: center;
  margin: 10px;
  background-color: #11B14B;
  color: white;
  font-family: "Avenir Next", sans-serif;
  font-weight: 400;
  border: 0;
  width: 100px;
}

.resolvedEtr {
  color: green,
}

.incidentSingle {
  height: auto !important;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border: 0;
  background-color: #f3f3f3 !important;
}

.incidentSingle_alternate {
  height: auto !important;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border: 0;
  background-color: #fefefe !important;
}

.incidentSingle_inline {
  display: inline;
  vertical-align: middle !important;
  font-size: 1.2em;
}

.incidentSingle_inlineBump {
  display: inline;
  vertical-align: middle;
  margin: 0 5px;
}

.incidentSingle_inlinebelow {
  margin: 0;
  padding: 2px;
  font-size: 1.1em;
  color: rgb(66, 90, 112);
}

.incidentSingle_inlinebelowTitle {
  margin: 0;
  font-size: 14px;
  color: rgb(136, 136, 136);
}


.incidentSingle_tableInnerRow {
  display: flex;
  flex-direction: row;
}

.incidentSingle_tableAddtSection {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  margin: 10px 0 0 0;
  padding: 10px;
  border-top: 1px solid #ddd;
  height: auto;
}


/* Incident Button Grouping  */
.incidentSingle_btnBox {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  float:right;
}

.incidentSingle_mapBtn {
  padding: 5px;
  margin: 5px;
  height: 100%;
}

.incidentSingle_mapBtnInner {
  height: 100%;
  text-align: center;
  flex-direction: column;
}

.incidentSingle_mapBtnIcon {
  height: 35px;
  width: 35px;
  margin: 5px;
  justify-content: center;
}

.incidentSingle_mapBtn p {
  font-size: 1em;
  margin: 0;
  line-break:strict;
  line-height: 1em;
}

.incidentSingle_btnGroupInline {
  padding: 10px 0;
  flex-basis: 40%;
  text-align: right;
  display: inline-flex;
  flex-direction: column;
}

.incidentSingle_btnInline {
  margin: 5px;
  font-size: 1.2em;
  text-align: center;
}

.etoTooltip p {
  color: #FEFEFE;
  font-size: 13px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.filterContainer{
  font-size: 14px;
  color:#494949;
  padding: 10px;
  background-color: #FFFFFF;
  width: 540px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
}

.filterLayer{
  position: absolute;
  top: 40px;
  left: 0;
}

.filterModalCloseButton{
  padding: 5px;
  line-height: 0.9;
  float: right;
  &:hover{
    box-shadow: none;
  }
}

.addConditionButton{
  cursor: pointer;
  color:#40A4FC;
  font-weight: 500;
  margin: 0 10px;
  &:hover{
    text-decoration: underline;
  }
}

.filterRowContainer {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.filterSelector{
  min-width: 140px;
}

.filterSelectorValue{
  width: 140px;
  height: 38px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding-left: 10px;
}

.filterLiterals {
  margin: 0 10px;
}

.removeFilterIcon{
  padding: 5px;
  cursor: pointer;
  margin: 0 0 6px 5px;
  height: 22px;
  width: 22px;
  &:hover{
    background-color: #EFEFEF;
  }
}

.filterButton{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #494949;
  text-decoration: none;
  &:hover{
    color: #494949;
    text-decoration: none;
    box-shadow: none;
    background-color: #EFEFEF;
  }
  &:active{
    text-decoration: none;
  }
  &:focus{
    text-decoration: none;
  }
}

.filterIcon{
  margin-top: 3px;
  margin-right: 3px;
  width: 16px;
}

.filterButtonAssigned{
  color: #ffffff;
  background-color: rgba(46, 204, 113, 0.9);
  &:hover{
    color: #ffffff;
    background-color: rgba(43, 194, 107, 0.9);
  }
}

.assetsContainer {
  text-align: justify;
  display: inline-block;
}

.relatedAssetContainer {
  display: inline-block;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 5px 0 5px 5px;
  margin: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}


.relatedAssetSelector {
  border-radius: 2px;
  padding: 7px 3px;
}

.relatedAssetSelector:hover {
  background-color: #FFBDAD;
  color: #DE350B;
}
