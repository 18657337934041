.is_container {
  padding: 20px;
}

h3 {
  margin-bottom: 20px;
}

.generalInformation {
  margin-bottom: 20px;
}

.is_form {
  margin-top: 20px;
}

.is_col {
  margin: 10px 0;
}

.is_name {
  margin: 10px 0;
}

.group_label {
  margin-bottom: 0;
  margin-top: 1rem;
}

.collapseSectionBody {
  padding: 0 20px;
}

.collapseSectionHeader {
  text-align: left;
  background: none;
  color: black;
  font-size: 1.2em;
  border: 0;
  border-radius: 0;
}

.collapseSectionHeader:hover {
  background: none;
  color: #28a745;
  font-size: 1.2em;
  box-shadow: none;
  text-decoration: none;
}

.collapseSectionHeader:active {
  background: none !important;
  color: #28a745 !important;
}

.etrDatepickerPrepend {
  margin: 12px 8px;
}

.locationDetails {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 20px 0 5px;
}

.locationBarContainer {
  position: relative;
  box-sizing: border-box;
}

.locationBar {
  width: 100%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 0;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.submitButton:disabled {
  cursor: not-allowed;
}

.buttonLink, .buttonLink:hover {
  color: #007bff !important;
  background-color: transparent;
  border: none;
  padding: 0 !important;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
}
